import { observable } from 'mobx'
import { list, object, primitive, serializable } from 'serializr'
import { IWithUrl } from 'stores/contentStore/cmsAdapters/prismic/prismicSubcontentDataProcessor'
import {
  IUIElements,
  Thumbnail,
  UIElements,
} from 'stores/contentStore/contentTypes'
import FullscreenSubcontentAudio from '../subcontent_modules/audio/subcontent'

export interface IPodcastItemPrismicData {
  /* eslint-disable */
  primary: {
    series_name: string
    series_title_hub: string
    episodes_name: string
    series_description: string
    series_image: IWithUrl
    order_by_podcast_name: boolean
  }
  /* eslint-enable */
  items: Array<{ podcasts: { id: string } }>
  ranking: string
}
export interface IDossierItemPrismicData {
  /* eslint-disable */
  primary: {
    name: string
    articles_name: string
    description: string
    image: IWithUrl
    order_by_name: boolean
  }
  /* eslint-enable */
  items: Array<{ articles: { id: string } }>
}

export interface IPodcast {
  buttonTextShowMore: string
  buttonTextShowLess: string
  relatedPodcastsTitle: string
  relatedPodcastsButtonTextShowMore: string
  mobileDescriptionTitle: string
  initialNumberOfRelatedEpisodes: number
}

export class Podcast implements IPodcast {
  @serializable
  @observable
  buttonTextShowMore: string = ''

  @serializable
  @observable
  buttonTextShowLess: string = ''

  @serializable
  @observable
  relatedPodcastsTitle: string = ''

  @serializable
  @observable
  relatedPodcastsButtonTextShowMore: string = ''

  @serializable
  @observable
  mobileDescriptionTitle: string = ''

  @serializable
  @observable
  initialNumberOfRelatedEpisodes: number = 0
}
export interface NovartisIUIElements extends IUIElements {
  podcast?: Podcast
  podcastSeries?: Series[]
}

export interface ISeries {
  title: string
  hubTitle: string
  collectionTypeUnit: string
  description: string
  thumbnail?: Thumbnail
  ranking: number
  orderByName: boolean
}

export class Series implements ISeries {
  @serializable
  @observable
  title: string = ''

  @serializable
  @observable
  hubTitle: string = ''

  @serializable
  @observable
  collectionTypeUnit: string = ''

  @serializable
  @observable
  description: string = ''

  @serializable(object(Thumbnail))
  @observable
  thumbnail?: Thumbnail

  @serializable(list(primitive())) //this should be list(FullscreenSubcontentAudio) but then it breaks as of circular dependency
  @observable
  podcasts?: FullscreenSubcontentAudio[] = []

  @serializable(list(primitive()))
  @observable
  podcastIds: string[] = []

  @serializable
  @observable
  ranking: number = -1

  @serializable
  @observable
  orderByName: boolean = false
}

export class NovartisUIElements
  extends UIElements
  implements NovartisIUIElements {
  @serializable
  @observable
  podcast?: Podcast

  @serializable(list(object(Series)))
  @observable
  podcastSeries?: Series[] = []
}
