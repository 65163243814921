import { bind } from 'bind-decorator'
import { action, computed, observable } from 'mobx'
import InlineSubcontentWidget from 'components/factories/InlineSubcontentWidget'
import React from 'react'
import { primitive, serializable } from 'serializr'
import { breakpoints } from 'stores/breakpointsStore'
import { currentStory } from 'stores/currentStoryStore'
import { sliderStore } from 'stores/sliderStore'
import { Deferred, deferred } from 'utils/promise'
import { AnyObject } from 'utils/types'
import { navigationStore } from '../../navigationStore'
import { decodingRouterHelper } from '../../routerStore'
import { contentStore } from '../contentStore'

export interface IFullscreenSubcontent {
  id: string
  title: string
  secondaryTitle: string
  type: string
  uid: string
  thumbnail: string
  openAsOverlay?: boolean
  category?: string
}

export class FullscreenSubcontent implements IFullscreenSubcontent {
  constructor() {
    const deferredObj = deferred<boolean>()
    this.setDeferred(deferredObj)
  }

  // if two same inlineElements appear in the same chapter, there is a problem with the key that results in an error in a browser
  _counter = 0

  @observable
  deferred?: Deferred<boolean>

  @serializable(primitive())
  @observable
  id: string = ''

  @serializable
  @observable
  title: string = ''

  @serializable
  @observable
  secondaryTitle: string = ''

  @serializable
  @observable
  type = 'gallery'

  @serializable
  @observable
  uid: string = ''

  @serializable
  @observable
  thumbnail: string = ''

  @serializable
  @observable
  openAsOverlay?: boolean = false

  @serializable
  @observable
  category?: string = ''

  get shouldOpenAsOverlay() {
    /* eslint-disable */
    return this.openAsOverlay || breakpoints.phone
    /* eslint-enable */
  }

  @observable
  heightOfSection: number = 1.2

  @observable
  heightOfSectionMobile: number = 2

  // This property is to distinguish backgrounds that have no text associated with them,
  // that should have size of their own and are always in fullscreen mode, i.e.
  // they don't switch to FS mode and don't block scroll on click(e.g. quizzes)

  get fullscreenBlockingScroll() {
    return true
  }

  getIcon(_alt?: boolean) {
    return ''
  }

  component: JSX.Element | null = null

  @computed
  get backgroundUrl() {
    return this.thumbnail
  }

  parseData(_data: unknown) {
    return {}
  }

  @computed
  get isFullscreenOrOverlay() {
    const parsed = decodingRouterHelper.qsParsed
    const isOverlay =
      this.shouldOpenAsOverlay &&
      parsed.overlayId &&
      parsed.overlayId === this.id
    const isFullscreen = !!(
      parsed.subcontentId && parsed.subcontentId === this.id
    )
    /* eslint-disable */
    return isOverlay || isFullscreen
    /* eslint-enable */
  }

  @computed
  get isOverlay() {
    const parsed = decodingRouterHelper.qsParsed
    return !!(parsed.overlayId && parsed.overlayId === this.id)
  }

  @bind
  @action
  enterFullscreen() {
    const params = decodingRouterHelper.qsParsed
    if (this.shouldOpenAsOverlay) {
      params.overlay = 'true'
      params.overlayId = this.id
    } else {
      params.fullscreen = 'true'
      params.subcontentId = this.id
    }
    navigationStore.replaceParams(params, true)
  }

  activateFullScreen(
    subchapterId: string,
    ref: React.RefObject<HTMLDivElement> | undefined
  ) {
    currentStory.setActiveSectionId(subchapterId)
    if (ref?.current?.scrollIntoView) {
      ref.current.scrollIntoView({
        block: 'start',
        inline: 'center',
        behavior: 'smooth',
      })
    }
    this.enterFullscreen()
  }

  quitFullscreen() {
    const clearedParams = navigationStore.clearQueryParams([
      'fullscreen',
      'subcontentId',
      'overlay',
      'overlayId',
      'origin',
    ])
    const params = {
      ...decodingRouterHelper.qsParsed,
      ...clearedParams,
    }
    navigationStore.replaceParams(params, true)
  }

  @computed
  get displayedTitle() {
    return this.title
  }

  @computed
  get shouldRender(): boolean {
    return true
  }

  @observable
  shouldAppearInMenu: boolean = true

  createInlineElement(cb: () => void, rest?: AnyObject): JSX.Element {
    return (
      <InlineSubcontentWidget
        key={this.id + (this._counter++).toString()}
        subcontentId={this.id}
        handleClick={cb}
        {...rest}
      >
        <div>{this.title}</div>
      </InlineSubcontentWidget>
    )
  }

  createBackgroundComponent(
    _subchapterId: string,
    _bgRef?: React.RefObject<HTMLDivElement>,
    _isOverlay?: boolean,
    _scrollPos?: number | null
  ): JSX.Element | null {
    return null
  }

  @bind
  @action
  setDeferred(deferred: Deferred<boolean>) {
    this.deferred = deferred
  }

  @computed
  get turnToRender(): boolean {
    const parentChapter = contentStore.getParentChapterBySubcontentId(this.id)
    const parentSubchapter = contentStore.getParentSubchapterBySubcontentId(
      this.id
    )
    const isInActiveChapter = parentChapter === sliderStore.currentChapter
    const isFirstInChapter = parentSubchapter?.id
      ? parentChapter?.subchaptersIds.indexOf(parentSubchapter?.id) === 0
      : false
    const isActive =
      currentStory.activeSectionId === parentSubchapter?.id ||
      this.isFullscreenOrOverlay
    const previousChapterLoaded = !!contentStore.getChapterShiftedBy(
      -1,
      parentChapter
    )?.sectionsLoaded
    const nextChapterLoaded = !!contentStore.getChapterShiftedBy(
      1,
      parentChapter
    )?.sectionsLoaded
    const previousSubcontentLoaded = !!contentStore.getSubcontentShiftedBy(
      this.id,
      -1
    )?.resourcesLoaded
    const nextSubcontentLoaded = !!contentStore.getSubcontentShiftedBy(
      this.id,
      1
    )?.resourcesLoaded
    const neighbouringChapterAreReady =
      previousChapterLoaded || nextChapterLoaded
    const turnToRender =
      this.resourcesLoaded ||
      isActive ||
      (isInActiveChapter &&
        (nextSubcontentLoaded || previousSubcontentLoaded)) ||
      isFirstInChapter ||
      neighbouringChapterAreReady
    return turnToRender
  }

  @computed
  get resourcesLoaded() {
    const loadingStatus =
      contentStore.subcontentResourcesLoaded.get(this.id) ?? false
    return loadingStatus
  }

  @bind
  @action
  loadResources() {
    const loadingStatus = true
    contentStore.setResourcesLoadStatus(this.id, loadingStatus)
  }

  @bind
  resolvePromiseAndLoadResources() {
    if (this.deferred?.resolve && !this.resourcesLoaded) {
      this.deferred?.resolve(true)
      this.loadResources()
    }
  }
}
