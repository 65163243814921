/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Prismic from '@prismicio/client'
import { QueryParams } from '@prismicio/client'
import { PrismicDocument } from '@prismicio/types'
import { bind } from 'bind-decorator'
import { appConfig } from 'config'
import { computed, runInAction } from 'mobx'
import { deferred, Deferred } from 'utils/promise'
import {
  IChapter,
  IIntroPage,
  ISubchapter,
  IUIElements,
  LegalContent,
} from './contentStore/contentTypes'
import { IRawSubcontentData } from './contentStore/subcontent_modules/subcontentInterfaces'
import { languageStore } from './languageStore'
import * as prismicHelper from './prismicHelpers'

export type QueryParamsExtended = QueryParams & {
  fetchAll?: boolean
}

export class PrismicAdapter {
  _api: Deferred<Prismic.Client> = deferred<Prismic.Client>()

  protected readonly _allLanguages: Prismic.QueryParams = {
    lang: '*',
  }

  // TODO prismic update: Use Prismic.QueryParams here once all old api calls are gone.
  @computed
  protected get _currentLanguage() {
    return {
      lang: languageStore.language,
    }
  }

  @computed
  get defaultFetchOption() {
    return {
      pageSize: 100,
      lang: languageStore.language,
    }
  }

  @computed
  get defaultFetchOptionPageOne() {
    return {
      page: 1,
      ...this.defaultFetchOption,
    }
  }

  initPrismic() {
    this._api.resolve?.(Prismic.createClient(appConfig.prismicPath))
  }

  @bind
  protected async _getAllResults<
    T extends PrismicDocument<Record<string, any>, string, string>
  >(q: string | string[], maybeOptions?: QueryParamsExtended) {
    let results: T[] = []
    let page = 1
    let more = false

    const api = await this._api.promise

    do {
      const r = await api.query<T>(q, {
        ...maybeOptions,
        page: page,
      })
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      page = r.page + 1
      more = page <= r.total_pages
      results = [...results, ...r.results]
    } while (more)
    return results
  }

  async getChapterLanguage(chapterUid: string) {
    const api = await this._api.promise
    const results = await api.getAllByUIDs(
      appConfig.prismicModelsNamesMap.page,
      [chapterUid],
      this._allLanguages
    )

    const languages: string[] = results
      .map((res) => res?.lang)
      .filter((lang) => lang !== undefined)
    if (languages.length) {
      return languages
    }
    return null
  }

  // TODO prismic update: these build methods are veeeeery close to each other :)
  private _buildChapterFieldQueries(fields?: {
    [key: string]: string
  }): string[] {
    return fields
      ? Object.keys(fields).map((f) => {
          return Prismic.predicates.at(
            `my.${appConfig.prismicModelsNamesMap.page}.${f}`,
            fields[f]
          )
        })
      : []
  }

  private _buildSubchapterFieldQueries(fields?: {
    [key: string]: string
  }): string[] {
    return fields
      ? Object.keys(fields).map((f) => {
          return Prismic.predicates.at(
            `my.${appConfig.prismicModelsNamesMap.section}.${f}`,
            fields[f]
          )
        })
      : []
  }

  private _buildChapterQuery(ids?: string[]) {
    return ids === undefined
      ? Prismic.predicates.at(
          'document.type',
          appConfig.prismicModelsNamesMap.page
        )
      : Prismic.predicates.in('document.id', ids)
  }

  private _buildSubchapterQuery(ids?: string[]) {
    if (ids) {
      const filteredIds = ids.filter((id) => id)

      if (filteredIds.length > 0) {
        return Prismic.predicates.in('document.id', ids)
      }
    }

    return Prismic.predicates.at(
      'document.type',
      appConfig.prismicModelsNamesMap.section
    )
  }

  // TODO prismic update: This method should be refactored.
  // options.fetchAll really necessary?
  async fetchChapters(
    // TODO prismic update: Type this correctly. Couldnt use QueryParams because fetchAll is not contained.
    options?: QueryParamsExtended,
    fields?: { [key: string]: string },
    ids?: string[]
  ) {
    const fieldQueries = this._buildChapterFieldQueries(fields)
    const query = this._buildChapterQuery(ids)

    if (options?.fetchAll) {
      delete options.fetchAll

      const results = await this._getAllResults([query, ...fieldQueries], {
        ...options,
        ...this.defaultFetchOptionPageOne,
      })
      const typedResults = results
      return this.saveChapters(typedResults, true)
    }

    const api = await this._api.promise
    const { results, total_pages: nOfPages } = await api.query(
      [query, ...fieldQueries],
      {
        ...this.defaultFetchOptionPageOne,
        ...options,
      }
    )
    const typedResults = results
    return this.saveChapters(typedResults, !!(options?.page === nOfPages))
  }

  // TODO prismic update: Saving chapters should not happen in prismicAdapter. It should happen in a contentStore
  saveChapters(data: PrismicDocument[], isLastPage: boolean) {
    const chapters: IChapter[] = []
    for (const chapter of data) {
      runInAction(() => {
        const newChapter = prismicHelper.parseChapter(chapter)
        chapters.push(newChapter)
      })
    }
    return {
      data: chapters.filter((chapter: IChapter) => chapter.idx >= 0),
      isLastPage,
    }
  }

  async fetchSubchapters(
    ids?: string[],
    options?: QueryParams,
    fields?: { [key: string]: string }
  ) {
    const fieldQueries = this._buildSubchapterFieldQueries(fields)
    const query = this._buildSubchapterQuery(ids)

    const results = await this._getAllResults([query, ...fieldQueries], {
      ...options,
      ...this.defaultFetchOptionPageOne,
    })

    const subchapters: ISubchapter[] = []
    for (const subchapter of results) {
      const typedResults = subchapter
      const newSubchapter: ISubchapter = await this._buildSubchapter(
        typedResults
      )
      subchapters.push(newSubchapter)
    }
    return subchapters
  }

  protected async _buildSubchapter(subchapterData: PrismicDocument) {
    const subcontent = await prismicHelper.parseSubcontent(
      subchapterData.data.body
    )
    const newSubchapter: ISubchapter = prismicHelper.createSubchapter(
      subchapterData,
      subcontent
    )
    return newSubchapter
  }

  async fetchFullscreenSubcontent(
    query: string[] = [],
    subcontents: string[] = []
  ) {
    const results = await this._getAllResults(
      [
        Prismic.Predicates.any(
          'document.type',
          subcontents.length > 0
            ? subcontents
            : appConfig.prismicModelsNamesMap.subcontentNamesArray
        ),
        ...query,
      ],
      this.defaultFetchOption
    )
    const subcontentData: IRawSubcontentData[] = []
    for (const subcontent of results) {
      subcontentData.push(prismicHelper.parseFullScreenSubcontent(subcontent))
    }
    return subcontentData
  }

  async fetchVideoSubcontents() {
    const api = await this._api.promise
    const results = await api.getAllByType(
      appConfig.prismicModelsNamesMap.subcontentNamesArray[0],
      this._currentLanguage
    )

    return results.map((result) =>
      prismicHelper.parseFullScreenSubcontent(result)
    )
  }

  async getAllByTypes(types: string[]) {
    const allResults: PrismicDocument[] = []
    for (const type of types) {
      const api = await this._api.promise
      const results = await api.getAllByType(type, this._currentLanguage)
      allResults.concat(results)
    }
    return allResults
  }

  async fetchImpressum() {
    const api = await this._api.promise
    const results = await api.getAllByType(
      // TODO prismic update: is that correct?
      appConfig.prismicModelsNamesMap.legal,
      this._currentLanguage
    )
    const impressum: LegalContent[] = []
    for (const document of results) {
      if (
        // TODO prismic update: slugs are deprecated!
        document.slugs.indexOf(appConfig.prismicModelsNamesMap.impressumSlug) >=
        0
      ) {
        const parsedImpressum = prismicHelper.parseImpressum(document)
        impressum.push(parsedImpressum)
      }
    }
    return impressum[0]
  }

  async fetchQuellen() {
    const api = await this._api.promise
    const results = await api.getAllByType(
      // TODO prismic update: is that correct?
      appConfig.prismicModelsNamesMap.legal,
      this._currentLanguage
    )
    const quellen: LegalContent[] = []
    for (const document of results) {
      if (
        document.slugs.indexOf(appConfig.prismicModelsNamesMap.quellenSlug) >= 0
      ) {
        const parsedQuellen = prismicHelper.parseQuellen(document)
        quellen.push(parsedQuellen)
      }
    }
    return quellen[0]
  }

  async fetchDataPrivacy() {
    const api = await this._api.promise
    const results = await api.getAllByType(
      // TODO prismic update: is that correct?
      appConfig.prismicModelsNamesMap.legal,
      this._currentLanguage
    )

    const dataPrivacy: LegalContent[] = []
    for (const document of results) {
      if (
        document.slugs.indexOf(
          appConfig.prismicModelsNamesMap.dataPrivacySlug
        ) >= 0
      ) {
        const parsedDataPrivacy = prismicHelper.parseQuellen(document)
        dataPrivacy.push(parsedDataPrivacy)
      }
    }
    return dataPrivacy[0]
  }

  async fetchGlossary() {
    const api = await this._api.promise
    const results = (await api.getByType('glossar', this._currentLanguage))
      .results

    if (!results[0]) {
      return
    }
    return prismicHelper.parseGlossary(results[0])
  }

  async fetchIntroPage() {
    const introPage: IIntroPage[] = []
    for (const language of languageStore.languages) {
      const lang = language.code
      const api = await this._api.promise
      const results = (await api.getByType('intro_page', { lang })).results

      if (!results[0]) {
        const initializedIntroPage = prismicHelper.initIntroPage(lang)
        introPage.push(initializedIntroPage)
      } else {
        const data = results[0].data
        introPage.push({
          lang,
          ...prismicHelper.parseIntro(data),
        })
      }
    }
    return introPage
  }

  async fetchUIElements(
    additionalHandlers?: Array<{
      forType: string
      handlerFunc: (item: any, uiElements: IUIElements) => void
    }>
  ) {
    const api = await this._api.promise
    const results = await api.getAllByType('ui_elements', this._currentLanguage)
    if (!results || !results[0]) {
      return null
    }

    const uiElements: IUIElements = prismicHelper.parseCommonUIElements(results)

    for (const item of results[0].data.body) {
      if (item.slice_type === 'cookies_banner') {
        uiElements.cookiesBanner =
          prismicHelper.parseCookiesBanner(item) ?? ({} as any)
      }
      if (item.slice_type === 'meta_tabs') {
        uiElements.metaTabs = prismicHelper.parseMetaTabs(item) ?? []
      }
      if (item.slice_type === 'video_documentary_banner') {
        uiElements.videoBanner =
          prismicHelper.parseVideoBanner(item) ?? ({} as any)
      }
      if (item.slice_type === 'menu_items') {
        uiElements.menuItems = prismicHelper.parseMenuItems(item) ?? ({} as any)
      }
      if (item.slice_type === 'static_ui_texts') {
        uiElements.staticUITexts = prismicHelper.parseUIElementStaticUIText(
          item
        )
      }
      if (item.slice_type === 'loading_screen') {
        uiElements.loadingScreen = prismicHelper.parseUIElementLoadingScreen(
          item
        )
      }
      if (item.slice_type === 'marketing_popup') {
        uiElements.signupBanner = prismicHelper.parseUIElementMarketingPopup(
          item
        )
      }
      if (item.slice_type === 'legal_menu') {
        uiElements.legalMenu = prismicHelper.parseUIElementLegalMenuDirect(item)
      }

      if (item.slice_type === 'social_menu') {
        uiElements.socialMenu = prismicHelper.parseUIElementLegalSocialMenu(
          item
        )
      }

      if (additionalHandlers) {
        additionalHandlers.forEach((additionalHandler) => {
          if (item.slice_type === additionalHandler.forType) {
            additionalHandler.handlerFunc(item, uiElements)
          }
        })
      }
    }
    return uiElements
  }

  async fetchChapterByUid(uid: string) {
    const api = await this._api.promise
    const chapter = await api.getByUID(
      appConfig.prismicModelsNamesMap.page,
      uid,
      this._currentLanguage
    )
    return prismicHelper.parseChapter(chapter)
  }

  async fetchById(id: string) {
    if (!id) {
      return null
    }

    let results = null
    try {
      const api = await this._api.promise
      results = await api.getByID(id, this._currentLanguage)
    } catch {
      return null
    }

    if (!results) {
      return null
    }

    const parsed = prismicHelper.parseFetchedById(results)
    return parsed
  }

  async documentExistsInLanguage(documentType: string, uid: string) {
    for (const language of languageStore.languages) {
      try {
        const api = await this._api.promise
        const document = await api.getByUID(documentType, uid, {
          lang: language.code,
        })

        if (document) {
          return language
        }
      } catch {
        continue
      }
    }

    return null
  }
}

export const prismicAdapter = new PrismicAdapter()
