import { WithStyles, withStyles } from '@material-ui/styles'
import { MenuButton } from 'components/common/elements/buttons'
import { observer } from 'mobx-react'
import React from 'react'
import { Portal } from 'react-portal'
import { ContentSliderStyles } from './contentSlider.style'
type BurgerProps = WithStyles<typeof ContentSliderStyles>

@observer
class BurgerCls extends React.Component<BurgerProps> {
  render(): React.ReactNode {
    const { classes } = this.props
    return (
      <Portal>
        <div data-at="menuButton" className={classes.burger}>
          <MenuButton />
        </div>
      </Portal>
    )
  }
}
export default withStyles(ContentSliderStyles, { name: 'Burger' })(BurgerCls)
