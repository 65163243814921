import bind from 'bind-decorator'
import { appConfig } from 'config'
import { action, computed, observable } from 'mobx'
import { breakpoints } from './breakpointsStore'
import { legalStore } from './legalStore'
import { navigationStore } from './navigationStore'
import { decodingRouterHelper } from './routerStore'
import { topBarStore } from './topBarStore'

export type ContentMenuTextTypes = 'impressum' | 'data-privacy'

class Menu {
  @observable showChaptersBar = false
  @observable menuButtonHidden = false
  @observable disableAnimation = false

  @bind
  @action
  toggleChaptersBar(value: boolean) {
    this.showChaptersBar = value
  }

  @bind
  @action
  closeMenu() {
    if (appConfig.showLegalInsideMenu && legalStore.showingLegal) {
      legalStore.toggleImpressum()
    }
    const params = {
      ...decodingRouterHelper.qsParsed,
      ...navigationStore.clearQueryParams(['menu', 'openMobileChapter']),
    }
    navigationStore.replaceParams(params, true)
  }

  @computed
  get open() {
    const parsed = decodingRouterHelper.qsParsed
    return parsed.menu && parsed.menu === 'open'
  }

  @computed
  get expandedMenuChapter() {
    const parsed = decodingRouterHelper.qsParsed
    if (!parsed.openMobileChapter) {
      return -1
    }
    const chapterNumber = parseInt(parsed.openMobileChapter as string, 10)
    if (chapterNumber == null) {
      return -1
    }
    return chapterNumber
  }

  @computed
  get buttonIsVisible() {
    let result = true
    if (
      breakpoints.desktop &&
      (topBarStore.activeTab ?? legalStore.showingLegal)
    ) {
      result = false
    }
    if (this.menuButtonHidden) {
      result = false
    }
    return result
  }

  @computed
  get expanded() {
    return this.expandedMenuChapter > -1
  }

  @bind
  @action
  toggleChapter(section: number) {
    if (section >= 0) {
      const params = decodingRouterHelper.qsParsed
      if (section === this.expandedMenuChapter) {
        params.openMobileChapter = null
      } else {
        params.openMobileChapter = section.toString()
      }
      navigationStore.replaceParams(params, false)
    }
  }

  @bind
  @action
  openMenu(showChaptersBar: boolean = true) {
    this.showChaptersBar = showChaptersBar
    const params = decodingRouterHelper.qsParsed
    params.menu = 'open'
    navigationStore.replaceParams(params, true)
  }

  @bind
  @action
  toggleMenuButtonAppearance() {
    this.menuButtonHidden = !this.menuButtonHidden
  }

  @bind
  @action
  setDisableAnimation(value: boolean) {
    this.disableAnimation = value
  }
}

export const menuStore = new Menu()
