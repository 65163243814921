import { DateTime } from 'luxon'
import * as PrismicDOM from 'prismic-dom'
import {
  ContentTypes,
  Dossier,
  ISubchapter,
  IUIElements,
  PartialChapter,
} from 'stores/contentStore/contentTypes'
import {
  IPrismicChapter,
  parseBackIssues,
  parseThumbnail,
} from 'stores/prismicHelpers'
import { IHub } from 'stores/types/hub'
import {
  IDossierItemPrismicData,
  IPodcastItemPrismicData,
  NovartisIUIElements,
  Series,
} from './contentTypes'

export const createPartialChapter = (
  chapter: IPrismicChapter
): PartialChapter => {
  const data = chapter.data
  return {
    id: chapter?.id || '',
    uid: chapter?.uid ?? '',
    type: 'chapter' as ContentTypes,
    idx: !(data.chapter_number ?? data.chapter_number === 0)
      ? 0
      : data.chapter_number > 0
      ? data.chapter_number
      : -1,
    title:
      (data.chapter_title && PrismicDOM.RichText.asText(data.chapter_title)) ??
      '',
    category: data.category ?? '',
    categoryAlternativeText: data.alternative_text_to_category ?? '',
    author: (data.author && PrismicDOM.RichText.asHtml(data.author)) ?? '',
    thumbnail: (data.thumbnail && parseThumbnail(data.thumbnail)) ?? null,
    lottieChapterTitleAnimation: data.lottie_chapter_title_animation?.url ?? '',
    // Ranking is 1-3, so to show missing ranking use -1
    ranking: parseInt(data.ranking) || -1,
    publicationDate: data.date_of_publication ?? DateTime.local(),
    hubTeaserText:
      (data.hub_teaser_text &&
        PrismicDOM.RichText.asHtml(data.hub_teaser_text)) ??
      '',
    tags: data.tags
      ? data.tags.map((tagData: { tag: string }) => tagData.tag)
      : [],
    subchaptersIds: data.subchapters
      ? data.subchapters.map(
          (subchapterData: { [id: string]: ISubchapter }) =>
            subchapterData.subchapter.id
        )
      : [],
  }
}

type HubFeature = PartialChapter | null
// TODO-Interface: Create interface
export const parseHub = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any,
  featuredArticlesTopBannerIds: string[],
  featured1: HubFeature,
  featured2: HubFeature
): IHub => {
  return {
    dummyThumbnail: data.dummy_image.url,
    articlesLoaded: data.default_amount_of_articles_shown,
    featuredArticlesTopBannerIds,
    featuredArticle1: featured1,
    featuredArticle2: featured2,
    subscribeData: {
      type: 'subscribe' as const,
      text:
        data.subscribe_description.length > 0
          ? PrismicDOM.RichText.asHtml(data.subscribe_description)
          : '',
      buttonText: data.button_text_subscribe,
      placeholderText: data.subscribe_input_placeholder_text,
      mailTo: data.mailto_address_button_subscribe,
      mailToSubject:
        (data.mailto_subject_subscribe &&
          PrismicDOM.RichText.asText(data.mailto_subject_subscribe)) ||
        '',
      mailToBody:
        (data.mailto_body_subscribe &&
          PrismicDOM.RichText.asText(data.mailto_body_subscribe)) ||
        '',
    },
    pensionersData: {
      type: 'pensioners' as const,
      text:
        data.subscribe_description.length > 0
          ? PrismicDOM.RichText.asHtml(data.pensioners_description)
          : '',
      buttonText: data.button_text_pensioners,
      mailTo: data.mailto_address_button_pensioners,
      mailToSubject:
        (data.mailto_subject_pensioners &&
          PrismicDOM.RichText.asText(data.mailto_subject_pensioners)) ||
        '',
      mailToBody:
        (data.mailto_body_pensioners &&
          PrismicDOM.RichText.asHtml(data.mailto_body_pensioners)) ||
        '',
    },
    aboutLiveData: {
      type: 'about' as const,
      text:
        data.subscribe_description.length > 0
          ? PrismicDOM.RichText.asHtml(data.about_live)
          : '',
      buttonText: data.button_text_about_live,
      mailTo: data.mailto_address_button_about_live,
      mailToBody:
        (data.mailto_subject_about_live &&
          PrismicDOM.RichText.asText(data.mailto_subject_about_live)) ||
        '',
    },

    backIssues: parseBackIssues(data.body) ?? [],
  }
}

export const parsePodcastSeries = (item?: IPodcastItemPrismicData) => {
  if (!item) {
    return
  }
  const newSerie: Series = {
    title: item.primary.series_name,
    hubTitle: item.primary.series_title_hub ?? '',
    collectionTypeUnit: item.primary.episodes_name ?? '',
    description: PrismicDOM.RichText.asHtml(item.primary.series_description),
    thumbnail: parseThumbnail(item.primary.series_image),
    // eslint-disable-next-line
    podcastIds: item.items.map((podcastItem) => podcastItem.podcasts.id),
    ranking: parseInt(item.ranking) ?? -1,
    orderByName: item.primary.order_by_podcast_name ?? false,
  }
  return newSerie
}

export const parseDossier = (item?: IDossierItemPrismicData) => {
  if (!item) {
    return
  }
  const newDossier: Dossier = {
    title: item.primary.name ?? '',
    collectionTypeUnit: item.primary.articles_name ?? '',
    description: item.primary.description
      ? PrismicDOM.RichText.asHtml(item.primary.description)
      : '',
    thumbnail: parseThumbnail(item.primary.image),
    articleIds: item.items.map((articleItem) => articleItem.articles.id),
    ranking: -1,
    orderByName: item.primary.order_by_name ?? false,
  }
  return newDossier
}

export const _parsePodcastSeries = (
  item: IPodcastItemPrismicData,
  uiElements: NovartisIUIElements
) => {
  const podcasts = parsePodcastSeries(item)

  const podcastSeries = uiElements.podcastSeries ?? []
  if (podcasts) {
    podcastSeries.push(podcasts)
  }

  uiElements.podcastSeries = podcastSeries
}

export const _parseDossier = (item: unknown, uiElements: IUIElements) => {
  const typedItem = item as IDossierItemPrismicData
  const dossiers = parseDossier(typedItem)

  if (uiElements.dossiers && dossiers) {
    uiElements.dossiers.push(dossiers)
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const parseUIElementPodcast = (item: any) => {
  const {
    button_text_show_more: buttonTextShowMore,
    button_text_show_less: buttonTextShowLess,
    related_podcasts_title: relatedPodcastsTitle,
    related_podcasts_button_text_show_more: relatedPodcastsButtonTextShowMore,
    mobile_description_title: mobileDescriptionTitle,
    initial_number_of_related_episodes: initialNumberOfRelatedEpisodes,
  } = item.primary

  return (
    {
      buttonTextShowMore,
      buttonTextShowLess,
      relatedPodcastsTitle,
      relatedPodcastsButtonTextShowMore,
      mobileDescriptionTitle,
      initialNumberOfRelatedEpisodes,
    } || {}
  )
}
