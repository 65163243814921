import {
  prismicAdapter as daniothPrismicAdapter,
  DaniothPrismicAdapter,
} from 'projects/danioth/stores/prismicAdapter'
import {
  NovartisPrismicAdapter,
  prismicAdapter as novartisPrismicAdapter,
} from 'projects/novartis_live/stores/prismicAdapter'
import {
  NuclearGamesAdapter,
  prismicAdapter as nuclearGamesAdapter,
} from 'projects/nuclear_games/stores/prismicAdatpter'
import {
  BilanzAdapter,
  prismicAdapter as bilanzAdapter,
} from 'projects/bilanz/stores/prismicAdatpter'

import { AppName } from 'utils/enums'
import {
  prismicAdapter as defaultPrismicAdapter,
  PrismicAdapter,
} from './prismicAdapter'

type Adapter =
  | NovartisPrismicAdapter
  | NuclearGamesAdapter
  | DaniothPrismicAdapter
  | BilanzAdapter
  | PrismicAdapter
export const getAdapter = (): Adapter => {
  switch (process.env.REACT_APP_PROJECT_NAME) {
    case AppName.Novartis:
      return novartisPrismicAdapter
    case AppName.NuclearGames:
      return nuclearGamesAdapter
    case AppName.Danioth:
      return daniothPrismicAdapter
    case AppName.Bilanz:
      return bilanzAdapter
    default:
      return defaultPrismicAdapter
  }
}
