import { WithStyles, withStyles } from '@material-ui/styles'
import { bind } from 'bind-decorator'
import classnames from 'classnames'
import LoadingSpinner from 'components/LoadingSpinner'
import Overlay from 'components/Overlay'
import { appConfig } from 'config'
import { action, computed, IReactionDisposer, observable, reaction } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { Portal } from 'react-portal'
import { RouteComponentProps } from 'react-router-dom'
import SwipeableViews from 'react-swipeable-views'
import { SlideRendererParams, virtualize } from 'react-swipeable-views-utils'
import { breakpoints } from 'stores/breakpointsStore'
import { contentStore } from 'stores/contentStore/contentStore'
import { Chapter } from 'stores/contentStore/contentTypes'
import { currentStory } from 'stores/currentStoryStore'
import { menuStore } from 'stores/menuStore'
import { MatchParams, navigationStore } from 'stores/navigationStore'
import { decodingRouterHelper, routerStore } from 'stores/routerStore'
import { sliderStore } from 'stores/sliderStore'
import { topBarStore } from 'stores/topBarStore'
import { videoStore } from 'stores/videoStore'
import { isIE } from 'utils/platform'
import Burger from './Burger'
import { ChaptersBar } from './chaptersBar'
import { ContentSliderStyles } from './contentSlider.style'
import { Slide } from './Slide'

const SwipeableViewsVirtual = virtualize(SwipeableViews)

interface IContentSliderProps
  extends RouteComponentProps<MatchParams>,
    WithStyles<typeof ContentSliderStyles> {}

@observer
class ContentSlider extends React.Component<IContentSliderProps> {
  swiper?: JSX.Element
  @observable disableAnimation = false

  @observable shouldUseLoadingScreen = false

  disposers: IReactionDisposer[] = []

  constructor(props: IContentSliderProps) {
    super(props)
    this.disposers.push(
      reaction(
        () => {
          return sliderStore.currentChapter
        },
        (chapter: Chapter | null | undefined) => {
          if (chapter?.isScrollytellingChapter) {
            this.setLoadingScreen(true)
          } else {
            this.setLoadingScreen(false)
          }
        },
        {
          fireImmediately: true,
        }
      )
    )
    this.disposers.push(
      reaction(
        () => {
          return contentStore.chaptersFetchedAndSet
        },
        (timeToUpdateQueryParams: boolean) => {
          if (timeToUpdateQueryParams) {
            this.updateFullscreenQueryParams()
          }
        },
        {
          fireImmediately: true,
        }
      )
    )
  }

  async componentDidMount() {
    document.addEventListener('keyup', this.escapePress, false)
    await this.fetchChaptersAndGoToSlide()
  }

  componentWillUnmount() {
    for (const disposer of this.disposers) {
      disposer()
    }
    document.removeEventListener('keyup', this.escapePress)
  }

  updateFullscreenQueryParams() {
    let params = decodingRouterHelper.qsParsed
    if (params.overlayId && breakpoints.desktop) {
      const id: string = params.overlayId as string
      const subcontent = contentStore.getSubcontentIfExists(id)
      // If subcontent in fullscreen always opens as overlay, don't update url
      if (subcontent?.openAsOverlay) {
        return
      }
      const clearedParams = navigationStore.clearQueryParams([
        'overlay',
        'overlayId',
      ])
      params = {
        ...params,
        ...clearedParams,
      }
      params.subcontentId = id
      params.fullscreen = 'true'
    } else if (params.subcontentId && breakpoints.phone) {
      const id = params.subcontentId
      const clearedParams = navigationStore.clearQueryParams([
        'fullscreen',
        'subcontentId',
      ])
      params = {
        ...params,
        ...clearedParams,
      }
      params.overlayId = id
      params.overlay = 'true'
    }
    navigationStore.replaceParams(params, false)
  }

  @bind
  @action
  setLoadingScreen(value: boolean) {
    this.shouldUseLoadingScreen = value
  }

  escapePress(e: KeyboardEvent) {
    if (
      e.key === 'Escape' &&
      currentStory.vertiefungExpanded &&
      currentStory.activeSectionId
    ) {
      const currentSection = contentStore.subchapters.get(
        currentStory.activeSectionId
      )
      if (!currentSection) {
        return
      }
      const currentSubcontent = contentStore.getSubcontentIfExists(
        currentSection.subcontentId
      )
      if (currentSubcontent) {
        currentSubcontent.quitFullscreen()
      }
    }
  }

  @bind
  @action
  setDisableAnimation(value: boolean) {
    this.disableAnimation = value
  }

  async componentDidUpdate(prevProps: IContentSliderProps) {
    if (this.props.match.params.slideUid !== prevProps.match.params.slideUid) {
      const doNotChangeSlide = true
      await this.fetchChaptersAndGoToSlide(doNotChangeSlide)
    }
  }

  @bind
  async fetchChaptersAndGoToSlide(doNotChangeSlide?: boolean) {
    await contentStore.fetchData()
    const uid = this.props.match.params.slideUid
    if (appConfig.isVideobook && !contentStore.chaptersFetchedAndSet) {
      /*
       ** This first call not only fetches current chapters but also sets the language correctly
       */
      await contentStore.checkIfChapterExists(uid)
      await contentStore.fetchChapters()
      await contentStore.fetchSubchapters()
    }
    if (!appConfig.isVideobook) {
      await contentStore.checkIfChapterExists(uid)
    }
    this.gotoSlide(
      this.uidToSlideIdx(this.props.match.params.slideUid),
      doNotChangeSlide
    )
  }

  public render() {
    const { classes } = this.props
    this.swiper = (
      <SwipeableViewsVirtual
        className={classes['swipeable-views-container']}
        animateTransitions={true}
        slideRenderer={(i) => this.slideRenderer(i)}
        enableMouseEvents
        index={sliderStore.currentSlideIdx}
        onChangeIndex={this.slideChanged}
        slideCount={contentStore.chapters.length}
        loop={false}
        disabled={this.disableSwipe}
        overscanSlideAfter={isIE ? 0 : 1}
        overscanSlideBefore={isIE ? 0 : 1}
      />
    )

    const shouldRender =
      sliderStore.showContent &&
      topBarStore.topBarShouldRender &&
      sliderStore.loadingScreenHidden

    return (
      <React.Fragment>
        <div
          className={classnames(classes['logo-container'], {
            [classes['logo-invisible']]: shouldRender,
          })}
        >
          <LoadingSpinner isLoadingScreen={this.shouldUseLoadingScreen} />
        </div>
        {
          <div className={classes.container}>
            {this.swiper}
            {breakpoints.desktop && sliderStore.chaptersBarVisible && (
              <ChaptersBar open={menuStore.showChaptersBar} />
            )}
            <Portal>{menuStore.buttonIsVisible && <Burger />}</Portal>
            <Overlay />
          </div>
        }
      </React.Fragment>
    )
  }

  slideRenderer(param: SlideRendererParams): JSX.Element {
    if (contentStore.chapters.length) {
      if (param.index < contentStore.chapters.length) {
        return (
          <Slide
            key={param.index}
            slideIdx={param.index}
            data={contentStore.chapters[param.index]}
          />
        )
      }
    }
    return <div key={Math.random()} />
  }

  @computed
  get disableSwipe() {
    const scrollySwipeDisabled =
      appConfig.touchSwipeToSwitchChaptersDisabled === 'scrolly' &&
      this.hasScrollySection &&
      navigator.maxTouchPoints &&
      navigator.maxTouchPoints > 2
    return (
      appConfig.touchSwipeToSwitchChaptersDisabled === 'all' ||
      scrollySwipeDisabled ||
      currentStory.vertiefungExpanded ||
      currentStory.swipeIsBlocked
    )
  }

  @computed
  get hasScrollySection() {
    if (
      sliderStore.currentChapter?.subcontents.length &&
      contentStore.subcontentResourcesLoaded.size
    ) {
      let chapterHasScrolly = false
      for (const subcontentId of contentStore.subcontentResourcesLoaded.keys()) {
        if (
          contentStore.getSubcontentIfExists(subcontentId)?.type ===
          'scrollytelling'
        ) {
          chapterHasScrolly = true
        }
      }
      return chapterHasScrolly
    }
    return false
  }

  @bind
  @action
  slideChanged(slideIdx: number) {
    if (this.disableAnimation || menuStore.disableAnimation) return
    sliderStore.setCurrentSlideIdx(slideIdx)
    navigationStore.blockScroll()
    videoStore.setPause(true)
    this.slideIdxUpdated()
  }

  @bind
  @action
  slideIdxUpdated() {
    const pathname = `/${appConfig.contentUnitUrl}/${this.slideIdxToUid(
      sliderStore.currentSlideIdx
    )}`
    if (routerStore.location.pathname !== pathname) {
      routerStore.push(pathname)
    }
  }

  @action
  gotoSlide(idx: number, _doNotChangeSlide?: boolean) {
    this.setDisableAnimation(true)
    //TODO: await correct slideIdx
    setTimeout(() => this.setDisableAnimation(false), 500)
    if (idx < contentStore.chapters.length && idx >= 0) {
      sliderStore.setCurrentSlideIdx(idx)
    } else {
      sliderStore.setCurrentSlideIdx(0)
    }
    if (!this.props.match.params.slideUid) {
      this.slideIdxUpdated()
    }
  }

  uidToSlideIdx(uid?: string): number {
    let i = 0
    for (const chapter of contentStore.chapters) {
      if (chapter.uid === uid) return i
      i++
    }

    return 0
  }

  slideIdxToUid(idx?: number): string {
    if (!idx) return contentStore.chapters[0].uid

    if (idx > 0 && idx < contentStore.chapters.length) {
      return contentStore.chapters[idx].uid
    }

    return contentStore.chapters[0].uid
  }
}

export default withStyles(ContentSliderStyles, { name: 'ContentSlider' })(
  ContentSlider
)
