import { PrismicDocument } from '@prismicio/types'
import { deserialize } from 'serializr'
import {
  Chapter,
  PartialChapter,
  Subchapter,
} from 'stores/contentStore/contentTypes'
import { PrismicAdapter } from 'stores/prismicAdapter'
import { IPrismicChapter, parseChapter } from 'stores/prismicHelpers'
import { IHub } from 'stores/types/hub'
import { NovartisIUIElements } from './contentTypes'
import {
  createPartialChapter,
  parseHub,
  parseUIElementPodcast,
  _parseDossier,
  _parsePodcastSeries,
} from './prismicHelpers'

export class NovartisPrismicAdapter extends PrismicAdapter {
  async fetchSubchapterById(id: string) {
    let result = null
    try {
      const api = await this._api.promise
      result = await api.getByID(id, this._currentLanguage)
    } catch {
      return null
    }

    if (!result) {
      return null
    }

    const newSubchapter = await this._buildSubchapter(result)
    return deserialize(Subchapter, newSubchapter)
  }

  async fetchUIElements(): Promise<NovartisIUIElements | null> {
    const result = super.fetchUIElements([
      { forType: 'podcast_series', handlerFunc: _parsePodcastSeries },
      { forType: 'dossier', handlerFunc: _parseDossier },
      { forType: 'podcast', handlerFunc: parseUIElementPodcast },
    ])

    return result
  }

  async fetchChapterById(id?: string) {
    if (!id) {
      return
    }

    let result = null
    try {
      const api = await this._api.promise
      result = await api.getByID(id, this._currentLanguage)
    } catch {
      return null
    }
    if (!result) {
      return null
    }

    return deserialize(Chapter, parseChapter(result))
  }

  async fetchHub(): Promise<IHub | null> {
    let results: PrismicDocument[] = []
    try {
      const api = await this._api.promise
      results = (await api.getByType('hub', this._currentLanguage)).results
    } catch (error) {
      console.log(error)
    }

    if (!results || !results[0]) {
      return null
    }

    const data = results[0].data
    const featuredData1 = await this.fetchById(data.featured_article_1.id)
    const featuredData2 = await this.fetchById(data.featured_article_2.id)
    const featuredArticlesTopBannerIds = data.featured_top_banner.map(
      // eslint-disable-next-line @typescript-eslint/naming-convention
      (val: { featured_article_top_banner: { id: unknown } }) =>
        val.featured_article_top_banner.id
    )
    const featured1: PartialChapter | null = featuredData1
      ? createPartialChapter(featuredData1.data as IPrismicChapter)
      : null
    const featured2: PartialChapter | null = featuredData2
      ? createPartialChapter(featuredData2.data as IPrismicChapter)
      : null

    return parseHub(data, featuredArticlesTopBannerIds, featured1, featured2)
  }
}

export const prismicAdapter = new NovartisPrismicAdapter()
